/**
 * Lazy loads resources, accepts an object as additional attributes to load (besides src).
 * @param {string} url
 * @param {object} additionalAttributes
 */
export default async function lazyLoadResource(url: string, additionalAttributes?: object) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', url);

    if (additionalAttributes) {
      for (const [key, value] of Object.entries(additionalAttributes)) {
        script.setAttribute(key, value);
      }
    }

    script.async = true;

    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export async function lazyLoadImageResource(url: string, additionalAttributes?: object) {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img');
    image.setAttribute('src', url);

    if (additionalAttributes) {
      for (const [key, value] of Object.entries(additionalAttributes)) {
        image.setAttribute(key, value);
      }
    }

    image.onload = resolve;
    image.onerror = reject;
    document.head.appendChild(image);
  });
}
