/**
 * Init DFP Ads:
 * loads the script, waits for the loaded event and then trigger the init scripts
 * for loading the divs included in renderer
 *
 * General concept:
 * - divs are integrated in renderer with all pre-decided data attributes with ad configurations
 * - divs are queries based on e.g. device category, webapp, etc in frontend and dynamically intiialized
 * - lazyload triggers are incluced in the data attributes as well and wired to the adEvent intersect observer events

 * */

import * as dfpAdManager from './dfpManager';
import { layoutDeviceCategory } from '../ui.utils';
import * as cmp from '../cmp';

let dfpLoaded = false;

/**
 * Load DFP ads
 * Only initialize if CMP Consent for "google generic" is given!
 */
export const initDfpAds = async () => {
  const cmpConsentGoogle = cmp.getConsent(cmp.Vendors.googleAds);
  const dfpAdsEnabled = window.analyticsData.b_adsDfp === 'true';
  const isBlockAds = window.location.href.includes('blockAds');
  const deviceCategory = layoutDeviceCategory();
  window.googletag = window.googletag || { cmd: [] };

  if (!dfpLoaded && dfpAdsEnabled && !isBlockAds && cmpConsentGoogle) {
    dfpLoaded = true;
    try {
      await loadAdScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
      dfpAdManager.initDfpAds(deviceCategory);
    } catch (error) {
      console.error('BunteAds: could not load DFP script');
    }
  }

  // Add the event listener for ad events fired from intersect observers
  window.addEventListener('adEvent', (event) => {
    if (dfpAdsEnabled && !isBlockAds) {
      dfpAdManager.adEventHandler(event, deviceCategory);
    }
  });
};

/**
 * loads the ads script and fires callback when script is loaded
 */
const loadAdScript = (scriptUrl: string) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.addEventListener('load', resolve);
    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.onerror = reject;
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0]?.appendChild(script);
  });

/**
 * ad event emitter
 */
export const emitAd = (type: string) => {
  const adEvent = new CustomEvent('adEvent', { detail: type });
  window.dispatchEvent(adEvent);
};
