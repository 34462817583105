// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const runOnDomLoaded = (fns: Function[]): void => {
  if (document.readyState === 'loading') {
    console.log('adding lazy loading listener');
    document.addEventListener('DOMContentLoaded', () => {
      fns.forEach((fn) => fn());
    });
  } else {
    console.log('init lazy loading directly');
    fns.forEach((fn) => fn());
  }
};

/**
 * Get deviceCategory - breakpoints are based on
 * media query added in baseLayoutSections.ts#100
 * in renderer
 *
 * DEVICE CATEGORY:
 * 0 - 699: mobile
 * 700 - 1099: tablet
 * 1100 - *: desktop
 */
export const calculateDeviceCategory = (): string =>
  window
    .getComputedStyle(document.querySelector('body') as HTMLElement, ':before')
    .getPropertyValue('content')
    .replace(/"/g, '');

export const deviceReady = (): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'device_ready',
      b_deviceCategory: calculateDeviceCategory,
    });
  }
};

/**
 * LAYOUT
 * 0 - 639: mobile, single column editor-ui-layout
 * 640 - 979: tablet, multi column editor-ui-layout
 * 980 - 1540: desktop, multi column editor-ui-layout
 * 1540 - *: desktop, wider multi column editor-ui-layout
 */
export const layoutDeviceCategory = (): string => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  if (viewportWidth <= 639) return 'mobile';
  if (viewportWidth >= 640 && viewportWidth <= 979) return 'tablet';
  if (viewportWidth >= 980 && viewportWidth <= 1539) return 'desktop';
  if (viewportWidth >= 1540) return 'desktop';

  return '';
};

export const initVerticals = (): void => {
  const pathVertical = window.location.href.split('/');
  const vertical = pathVertical[3];

  if (
    vertical === 'family' ||
    vertical === 'health' ||
    vertical === 'beauty' ||
    vertical === 'fashion' ||
    vertical === 'coaching' ||
    vertical === 'deals'
  ) {
    const node = document.getElementsByClassName('fe-header-channel-link')[0] as HTMLAnchorElement;
    if (node && node.hasAttribute('href')) {
      node.href += `${vertical}/`;
    }
  }
};

export const scrollTo = (selector: string): void => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    if (!element) return;
    const headerOffset = -65;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset + headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  });
};
