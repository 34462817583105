const errorMappings = {
  noUsername: 'Dein Benutzername fehlt.',
  noFirstname: 'Dein Vorname fehlt.',
  noLastname: 'Dein Nachname fehlt.',
  noPassword: 'Gib dein Passwort an.',
  noEmail: 'Gib deine E-Mail-Adresse an.',
  differentPasswords: 'Die Passwörter sind nicht identisch.',
  noApproval: 'Bitte markiere die Checkbox, um fortfahren zu können.',
  invalidEmail: 'Diese E-Mail-Adresse ist nicht gültig.\n Bitte überprüfe deine E-Mail-Adresse oder verwende eine andere.',
  noDigitInPassword: 'Das Passwort muss Zahlen enthalten.',
  noLowerCaseInPassword: 'Das Passwort muss Kleinbuchstaben enthalten.',
  noUpperCaseInPassword: 'Das Passwort muss Großbuchstaben enthalten.',
  passwordNotLongEnough: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
  defaultServerError: 'Leider ist ein Fehler aufgetreten.',
  emptyRequiredFields: 'Alle Pflichtfelder müssen ausgefüllt sein.',
  emptyRequiredField: 'Das Pflichtfeld muss ausgefüllt sein.',
  incorrectlyFilledInputs: 'Einige Eingaben sind falsch ausgefüllt.',
  threeDSecureError: `Bei der Authentifizierung ist ein Fehler aufgetreten,
          bitte versuche es erneut oder wähle eine andere Bezahlart.
          Bei Fragen kannst du dich an inside-support@bunte.de wenden.`,
  newsletterConfigEmailMismatch: 'Ungültige E-Mail-Adresse. Bitte nutze die E-Mail-Adresse, an die unsere Newsletter gesendet werden.',
};

const serverErrorMapping: Record<number, string> = {
  400: `Du hast bereits ein Konto bei uns.
  Bitte melde dich dort mit deiner E-Mail-Adresse an. Falls du dein Passwort vergessen hast, kannst du dieses dort auch zurücksetzen lassen.`,
  401: 'Nicht eingeloggt.',
  403: 'Ungültige Anmeldeinformationen.',
  404: 'Passwort konnte nicht zurückgesetzt werden.',
  412: 'Basisdaten konnten nicht validiert werden.',
  10003: 'Bitte bestätige deinen Account.',
};

export const getServerErrorMessage = (error: any) => {
  if (error?.data) {
    const { statusCode } = error.data;
    return serverErrorMapping[statusCode] || errorMappings.defaultServerError;
  }
  return errorMappings.defaultServerError;
};

export default errorMappings;
