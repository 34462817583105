import TrackingUtils from '@/utils/tracking/tracking.utils';
import { layoutDeviceCategory } from '@/utils/ui.utils';

const NavigationUtils = {
  initScrollableNavigation() {
    const navScroller = document.querySelector('#nav-scroller') as HTMLElement;
    const activeNavItem = document.querySelector('.nav-item-active') as HTMLElement;
    const halfScreen = window.innerWidth / 2;

    function calculateStep(clickedItem: DOMRect) {
      return clickedItem.width / 2 > halfScreen ? stepLeftToRight(clickedItem) : stepRightToLeft(clickedItem);
    }

    function stepLeftToRight({ left, width }: DOMRect) {
      return halfScreen - left - (halfScreen - width / 2);
    }

    function stepRightToLeft({ right, width }: DOMRect) {
      return right - halfScreen - width / 2;
    }
    if (activeNavItem) {
      const rect = activeNavItem.getBoundingClientRect();

      navScroller.scrollLeft += calculateStep(rect);
    }
  },
  menuCollapsing() {
    const menuCollapseToggle = (event: Event) => {
      if (layoutDeviceCategory() === 'mobile') return;

      event.preventDefault();
      const target = event.target as HTMLAnchorElement;
      if (target) {
        const previousActive = document.querySelector('.expanded-navigation');
        if (previousActive && previousActive !== target) {
          previousActive.classList.toggle('expanded-navigation');
        }
        target.classList.toggle('expanded-navigation');
      }
    };

    const collapsableMenuItems = Array.from(document.getElementsByClassName('nav-item-collapsable'));
    if (collapsableMenuItems.length) {
      collapsableMenuItems.forEach((elem) => elem.addEventListener('click', menuCollapseToggle, false));
    }
  },
  initSideMenuListeners() {
    const toggleSideNavigation = () => {
      const elem = document.querySelector('.header');
      const body = document.querySelector('body');
      const sideNav = document.querySelector('#header-side-nav a') as HTMLElement;

      if (elem) {
        elem.classList.toggle('header-active');
        elem.classList.remove('user-nav-active');
      }
      if (body) body.classList.toggle('body-fixed');
      if (sideNav) {
        sideNav.focus();
      }
    };

    const menuNavButtons = Array.from(document.querySelectorAll('[data-toggle="side-nav-menu-btn"]'));
    const isDesktop = screen.width >= 768;
    if (menuNavButtons) {
      menuNavButtons.forEach((elem) =>
        elem.addEventListener('click', () => {
          TrackingUtils.trackEvent({
            event: 'click',
            eventCategory: isDesktop ? 'navigation' : 'Custom Link Tracking',
            eventAction: isDesktop ? 'click' : 'impression',
            eventLabel: isDesktop ? 'menu-mehr' : 'mobile-menu',
          });
          toggleSideNavigation();
        })
      );
    }
  },
};
export default NavigationUtils;
