import TrackingUtils from '@/utils/tracking/tracking.utils';

/* BK-6252
 We track links as E-Commerce outbound links, if the author
 is from the Deals team.
 These are the authors that are in the Deals team.
 */

const dealsAuthor = [
  'dc1c81d1-3ef8-41b8-ab9b-1ec8c791fe8d', // BUNTE.de Deals-Team
  '1110ee87-78ca-4690-8e73-fd448f573a44', // Jasmin Weinberger
  '3d207818-e9bb-4c59-a219-5088bad10ed7', // Xaveria Inman
  '9b14572b-b17d-4f7b-a024-7c60267093c0', // Saskia Dietrich
  '78283168-a753-4c39-891d-50ee3c7d694a', // Leona Ullmann
  '66e9f141-7ddb-466a-8783-196a5e301d94', // Franziska Kiefl
];

const onOutgoingCommerceTracking = (event: Event) => {
  const element = event.currentTarget as HTMLAnchorElement;

  TrackingUtils.trackEvent({
    event: 'b_commerce_clicks',
    eventCategory: 'commerce_clicks',
    eventAction: element.hostname,
    eventLabel: element.href,
    eventValue: undefined,
  });
};

const onOutgoingTracking = (event: Event) => {
  const element = event.currentTarget as HTMLAnchorElement;
  const trackingLabel = element.getAttribute('data-tracking');
  const trackingCategory = element.getAttribute('data-tracking-category');
  const trackingAction = element.getAttribute('data-tracking-action');

  TrackingUtils.trackEvent({
    event: 'click',
    eventCategory: trackingCategory || '',
    eventAction: trackingAction || '',
    eventLabel: trackingLabel || '',
  });
};

const OutGoingTrackingUtils = {
  /**
   * adds a click interceptor to all links with data attribute
   * links and writes the tracking
   */
  trackDataAttributeHrefs: async () => {
    const trackableHref = document.querySelectorAll('a[data-tracking]');
    trackableHref.forEach((element) => {
      element.addEventListener('mouseup', onOutgoingTracking, false);
    });

    if (!window.dataLayer) return;
    // Track links as E-Commerce outbound link
    const authorUuid = window.dataLayer[0].b_articleAuthorUuid;
    if (dealsAuthor.includes(authorUuid)) {
      const linksInArticle = document.getElementById('article-content')?.querySelectorAll('a');
      if (!linksInArticle) return;
      linksInArticle.forEach((link) => {
        if (!link.href.includes('www.bunte.de')) {
          link.addEventListener('mouseup', onOutgoingCommerceTracking, false);
        }
      });
    }
  },
};

export default OutGoingTrackingUtils;
