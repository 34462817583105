import config from '@/config/index';
import localStorageUtils from '../localStorage.utils';
import CookieUtils from '../cookie.utils';

const getRegisterDateSincePaywallStart = (): boolean => {
  const registerDate = new Date(JSON.parse(localStorageUtils.get('user')).registerDate).getTime();
  const startDate = new Date('2019-10-04').getTime();
  const dateDiff = startDate - registerDate;
  return dateDiff < 0;
};

/**
 * sets the googletag targeting settings based globally on pubads
 */
export const getTargeting = () => {
  const data = window.analyticsData;
  const isPending = localStorageUtils.get('user:pending') || false;
  const isLoggedIn = CookieUtils.get(config.BUNTE_TOKEN) ? 'true' : 'false';
  const subscriptionState = localStorageUtils.get('user').subscriptionState;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const targetingSettings: any = {
    pageRessort: data.b_pageRessort || '',
    pageRessortL2: data.b_pageRessortL2 || '',
    pageRessortL3: data.b_pageRessortL3 || '',
    pageRessortL4: data.b_pageRessortL4 || '',
    productVersion: 3,
    visitorLoginState: isPending ? 'pending' : isLoggedIn,
  };

  if (subscriptionState) targetingSettings.SubscriptionState = subscriptionState;
  if (isLoggedIn === 'true') targetingSettings.userNewPayFunnel = getRegisterDateSincePaywallStart();

  if (window.analyticsData.b_pageType === 'article') {
    targetingSettings.articleId = data.b_articleId;
    targetingSettings.articleType = data.b_articleType || '';
    targetingSettings.pageStar = data.b_pageStar || '';
    targetingSettings.premium = data.b_premium || false;
  }

  return targetingSettings;
};
