const LocalStorageUtils = {
  get: (key: string) => JSON.parse(window.localStorage.getItem(key) || JSON.stringify('')),
  remove: (key: string) => window.localStorage.removeItem(key),
  set: (key: string, value: any, stringify = true) => {
    window.localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
  },
  getAndRemove: (key: string) => {
    const value = LocalStorageUtils.get(key);
    LocalStorageUtils.remove(key);
    return value;
  },
};

export default LocalStorageUtils;
