/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '@/config/index';
import CookieUtils from '../cookie.utils';
import WebAppUtils from '../webApp.utils';
import { getTargeting } from './adTargeting';
import { getConsent, Vendors } from '../cmp';
import localStorageUtils from '../localStorage.utils';

const badChars = /[&<>"'`=]/g;
const escapeCharacters: { [id: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '`': '&#x60;',
  '=': '&#x3D;',
};
export const escapeHtml = (value: string) => value.replace(badChars, (chr) => escapeCharacters[chr]!);

/**
 * Init the dfp ads based on the divs found for device category
 * Renderer takes care if ads should be shown based on article or channel based settings
 * by adding the div or not adding it
 * => This is an important change to how it was done in GTM
 */
export const initDfpAds = (deviceCategory: string) => {
  if (window.googletag && window.googletag.apiReady) {
    const googletag = window.googletag;

    // set the google targeting
    const targeting = getTargeting();

    let dfpAdPlacements: any = [];
    if (WebAppUtils.isApp()) {
      dfpAdPlacements = document.querySelectorAll(`div[data-ad-type="dfp"][data-ad-show-${deviceCategory}="1"][data-dfp-webapp="1"]`);
    } else {
      dfpAdPlacements = document.querySelectorAll(`div[data-ad-type="dfp"][data-ad-show-${deviceCategory}="1"][data-dfp-webapp="0"]`);
    }
    const adSlots: any = [];
    dfpAdPlacements.forEach((adPlacement: any) => {
      console.log(adPlacement);
      const slotName = adPlacement.getAttribute('data-dfp-slot');
      if (slotName === 'bunte_home_td_starlook' && !getConsent(Vendors.tracdelight)) return;
      const slot = addSlot(googletag, adPlacement);
      if (slot !== null) {
        Object.entries(targeting).forEach((item) => {
          slot.setTargeting(item[0], item[1]);
        });
        adSlots.push(slot);
      }
    });

    googletag.pubads().enableSingleRequest();
    if (!googletag.pubadsReady) {
      googletag.pubads().collapseEmptyDivs(true);
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    }

    adSlots.forEach((slot: any) => {
      googletag.display(slot);
      googletag.pubads().refresh([slot]);
      console.log('BunteAds: DFP loading dfp slot direct ', slot);
    });
  } else {
    setTimeout(() => initDfpAds(deviceCategory), 30);
  }
};

/**
 * Creates a google ad slot and registers it to the google ads publisher service
 */
const addSlot = (googletag: any, adPlacement: any) => {
  const dfpId = adPlacement.getAttribute('data-dfp-id');

  // Set the Ad-Size
  // Sizemapping is used for responsive ads, whereas size is used for single or multi size possibility
  const sizes = JSON.parse(adPlacement.getAttribute('data-dfp-size')) || [];
  const sizeMapping = JSON.parse(adPlacement.getAttribute('data-dfp-sizemapping'));
  const adSlot = googletag.defineSlot(dfpId, sizes, adPlacement.id);
  if (sizeMapping) {
    const mapping = googletag.sizeMapping();
    sizeMapping.forEach((sizeMap: any) => {
      mapping.addSize(sizeMap[0], sizeMap[1]);
    });
    adSlot.defineSizeMapping(mapping.build());
  }
  const style = adPlacement.getAttribute('data-style');
  if (style !== null) {
    adPlacement.setAttribute('style', style);
    adPlacement.removeAttribute('data-style');
  }
  adSlot.addService(googletag.pubads());
  googletag.cmd.push(() => adSlot);
  return adSlot;
};

/**
 * Listener for Ad Events
 * and initialize ads registered on their respective events.
 * Gallery ads are initialized in vue files which are not loaded
 * Blocks already initialized ads by adding a data-attribute "data-ad-loaded"
 */
export const adEventHandler = (event: any, deviceCategory: string) => {
  const googletag = window.googletag;
  const lazyLoadAdsToInit: any = [];
  let lazyLoadAdPlacements: any = [];
  if (WebAppUtils.isApp()) {
    lazyLoadAdPlacements = document.querySelectorAll(
      `div[data-ad-type="dfp"][data-ad-show-${deviceCategory}="1"][data-ad-vue="1"][data-dfp-webapp="1"]:not([data-ad-loaded="1"])`
    );
  } else {
    lazyLoadAdPlacements = document.querySelectorAll(
      `div[data-ad-type="dfp"][data-ad-show-${deviceCategory}="1"][data-ad-vue="1"][data-dfp-webapp="0"]:not([data-ad-loaded="1"])`
    );
  }
  lazyLoadAdPlacements.forEach((adPlacement: any) => {
    const slot = addSlot(googletag, adPlacement);
    if (slot !== null) {
      lazyLoadAdsToInit.push(slot);
      // mark as loaded
      adPlacement.setAttribute('data-ad-loaded', '1');
    }
  });
  console.log(`BunteAds: DFP loading dfp lazyload ${event.detail}: `, lazyLoadAdsToInit);
  lazyLoadAdsToInit.forEach((lazySlot: any) => {
    googletag.display(lazySlot);
    googletag.pubads().refresh([lazySlot]);
  });
};

/**
 * copies the inner html of custom IFrame postMessage Ads to the element
 * If it detects a script tag it will inject it into the container as well
 */
const setInnerHtml = (elm: any, html: any) => {
  elm.innerHTML = html;
  const scripts = elm.getElementsByTagName('script');
  // If we don't clone the results then "scripts"
  // will actually update live as we insert the new
  // tags, and we'll get caught in an endless loop
  const scriptsClone = [];
  for (let i = 0; i < scripts.length; i++) {
    scriptsClone.push(scripts[i]);
  }
  for (let i = 0; i < scriptsClone.length; i++) {
    const currentScript = scriptsClone[i];
    const s = document.createElement('script');
    // Copy all the attributes from the original script
    for (let j = 0; j < currentScript.attributes.length; j++) {
      const a = currentScript.attributes[j];
      s.setAttribute(a.name, a.value);
    }
    s.appendChild(document.createTextNode(currentScript.innerHTML));
    currentScript.parentNode.replaceChild(s, currentScript);
  }
};

/**
 * Listener of the iframe postmessage which is used to inject the HTML from
 * our native campaign ads to the parent html element if ata-dfp-pseudo="1" set
 * This is used for all the article card native ads.
 * For all other ads just leave them rendered in the iframe
 * DFP Ads template looks as follows
 * <script>
 *   var data = {
 *   'message': 'adContentAvailable',
 *   'adUnit' : '%%ADUNIT%%',
 *   'content': {
 *   'link': '%%CLICK_URL_UNESC%%%%DEST_URL%%',
 *   'image': '[%Image%]',
 *   'tag': '[%Tag%]',
 *   'kicker': '[%Kicker%]',
 *   'title': '[%Title%]'
 * }};
 *  * parent.postMessage(data , "*");
 * </script>
 *
 */
const iframeListener = async (evt: any) => {
  if (evt.data && evt.data.message === 'adContentAvailable') {
    console.log('ad data available: ', evt.data);
    const adContainer: any = document.querySelector(`div[data-dfp-id="${evt.data.adUnit}"]`);
    if (adContainer) {
      const stageType: any = adContainer.getAttribute('data-content-items');
      const content = evt.data.content;
      const vueComponent = `<ad-native-teaser stage-type="${stageType}" teaser="${escapeHtml(JSON.stringify(content))}"/>`;
      if (adContainer.getAttribute('data-dfp-pseudo') === '1') {
        // Mainly used for replacing article cards with content
        const parent = adContainer.parentElement;
        setInnerHtml(parent, vueComponent);
        parent.id = adContainer.id;
        await initTeaser();
      } else {
        // Render in div and get rid of iframe
        setInnerHtml(adContainer, evt.data.content);
      }
    }
  }
};

const initTeaser = async () => {
  const adNativeTeaser = (await import(/* webpackChunkName: "adNativeTeaser" */ '@/components/adNativeTeaser.component.vue')).default;
  if (window.bootVueComponents) window.bootVueComponents([adNativeTeaser]);
  else setTimeout(initTeaser, 1000);
};

// Listen to message from iFrame
window.addEventListener('message', iframeListener, false);
