import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/respimg/ls.respimg';

async function lazyLoader() {
  document.addEventListener('lazybeforeunveil', (e: any) => {
    if (e.target) {
      const bg = e.target.getAttribute('data-src-back');
      if (bg) {
        e.target.style.backgroundImage = `url(${bg})`;
      }
    }
  });
}

export default lazyLoader;
