export default function initMobileGalleryAdsObserver() {
  const mobileGalleryAdsInitialState = new Set();
  window.mobileGalleryAdsObserver = new IntersectionObserver(function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !mobileGalleryAdsInitialState.has(entry.target.id)) {
        mobileGalleryAdsInitialState.add(entry.target.id);
      } else if (mobileGalleryAdsInitialState.has(entry.target.id)) {
        const idx = entry.target.id.split('-').slice(-1)[0];
        if (!entry.isIntersecting) {
          window._aeq = window._aeq || [];
          window._aeq.push(['destroy', 'bunte_web_hor_' + idx]);
        } else {
          window._aeq = window._aeq || [];
          window._aeq.push(['getAdTag', { containerId: `bunte_web_hor_${idx}`, orientation: 'hor', index: parseInt(idx, 10) }]);
        }
      }
    });
  });
  if (window.mobileGalleryAdsDivs) {
    window.window.mobileGalleryAdsDivs?.forEach((elm) => {
      window.mobileGalleryAdsObserver?.observe(elm);
    });
  }
}
