interface ObserverConfig {
  elementId: string;
  threshold: number;
  callback: () => void;
}

export default function IntersectObserverForTracking(config: ObserverConfig): void {
  const contentPositionElement = document.getElementById(config.elementId);
  let observer: IntersectionObserver;

  function onIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        config.callback();
      }
    });
  }

  if (contentPositionElement) {
    observer = new IntersectionObserver(onIntersection, {
      rootMargin: '0px',
      threshold: config.threshold,
    });
    observer.observe(contentPositionElement);
  }
}

export function IntersectObserverForTrackingOfElemnent(element: Element, config: Omit<ObserverConfig, 'elementId'>): void {
  function onIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        config.callback();
      }
    });
  }

  const observer = new IntersectionObserver(onIntersection, {
    rootMargin: '0px',
    threshold: config.threshold,
  });
  observer.observe(element);
}
