import { IUserExistsResponse, IUserResponse } from '@bunte/bunte-api';

import {
  ICreateCustomerRequest,
  IUpdateCustomerRequest,
  ICodeMessageResponse,
  ISendNewPasswordRequest,
  IResendConfirmationRequest,
  ICreateUserResponse,
} from '@bunte-core/api-schema';

import axiosClient from '@/api/axios.client';
import { AxiosResponse } from 'axios';

export const login = (username: string, password: string): Promise<AxiosResponse> =>
  axiosClient
    .post('/user/login', {
      username,
      password,
    })
    .then((response) => response.data);

export const getUser = (): Promise<IUserResponse> => axiosClient.get('/user').then((response) => response.data);
export const userExists = (email: string): Promise<{ exists: boolean }> =>
  axiosClient
    .get('/user/exists', {
      params: { email },
    })
    .then((response) => response.data);

export const createUser = async (user: ICreateCustomerRequest): Promise<ICreateUserResponse> => {
  const response = await axiosClient.post('/user', user);
  return response.data;
};

export const updateUser = (user: IUpdateCustomerRequest): Promise<IUserResponse> =>
  axiosClient.put('/user', user).then((response) => response.data);

export const checkExistingEmail = async (email: string): Promise<IUserExistsResponse> => {
  const { data } = await axiosClient.get('/user/exists', {
    params: { email },
  });
  return data;
};

export const changePassword = async (password: {
  oldPassword: string;
  password: string;
  verifyPassword: string;
}): Promise<ICodeMessageResponse> => axiosClient.post('/user/password/change', password).then((response) => response.data);

export const resetPassword = async (username: string, newsletterBackend: 'elaine' | 'emarsys'): Promise<ICodeMessageResponse> =>
  axiosClient.post('/user/password/reset', { username }, { params: { newsletterBackend } }).then((response) => response.data);

export const verifyPasswordResetToken = async (token: string, verifyOnly?: boolean): Promise<ICodeMessageResponse> =>
  axiosClient.get(`/user/password/reset/${token}`, { params: { verifyOnly } }).then((response) => response.data);

export const resetPasswordWithToken = async (password: ISendNewPasswordRequest, token: string): Promise<ICodeMessageResponse> =>
  axiosClient.put(`/user/password/reset/${token}`, password).then((response) => response.data);

export const resendConfirmation = async (email: string, newsletterBackend: 'elaine' | 'emarsys'): Promise<IResendConfirmationRequest> =>
  axiosClient.post('/user/confirmation/resend', { email }, { params: { newsletterBackend } }).then((response) => response.data);

/**
 * Calls an API endpoint that registers a customer using the provided ID token.
 *
 * We're allowing an open format for the ID token, but we're only using the `email` and `sub` properties all of the time,
 * given_name and family_name are optional, they might not always be present in ID token.
 * If you need more properties you can add them to the `reqData` object.
 *
 * @param {Object} idToken - The ID token containing the customer's email, sub, and optional additional properties.
 * @param {string} idToken.email - The customer's email.
 * @param {string} idToken.sub - The customer's sub.
 * @param {Object.<string, string | number>} idToken.[k] - Additional properties of the ID token. Optional.
 * @return {Promise<string | Error>} A promise that resolves to the status of the registration ('exists' or 'created') or an error.
 */
export const registerCustomer = async (idToken: { email: string; sub: string; [k: string]: string | number }): Promise<string | Error> => {
  const reqData = {
    email: idToken.email,
    auth0Id: idToken.sub,
    given_name: idToken.given_name,
    family_name: idToken.family_name,
  };
  axiosClient
    .post('/api/v1/auth/user', reqData)
    .then((res) => {
      if (res.data.code === 201) {
        console.log('Auth0: customer created');
        return res.data.message;
      } else {
        console.log('Auth0: Unexpected response', res);
        return new Error('Unexpected response');
      }
    })
    .catch((err) => {
      if (err.response.status === 409) {
        console.log('Auth0: customer already exists, no action needed');
        return 'Auth0: customer already exists, no action needed';
      }
      console.error('Auth0: register customer error', err);
      return err;
    });
  return new Error('Unexpected case');
};
