import { Auth0User } from '@/store/user.store';

const addClasses = (attributeName: string): void => {
  [...document.querySelectorAll(`[${attributeName}]`)].forEach((element: any) => {
    element.className = element.attributes[attributeName].value;
  });
};

export const runCommands = (user: Auth0User | null): void => {
  let group = 'data-user-anonymous-class';
  if (user && user.email) {
    group = 'data-user-member-class';
  }
  console.log(group);
  addClasses(group);
};
