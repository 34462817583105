export type activeModalContent = 'ReportForm' | 'ReportConfirmation' | 'LoginForm' | 'DownloadApp' | null;
export type reportDetails = {
  galleryId: string;
  galleryTitle: string;
  producerDisplayName: string;
  producerId: string;
  feedType: string;
  elementCreative?: string;
} | null;
export interface globalModalStore {
  globalModalVisible: boolean;
  activeModalContent: activeModalContent;
  reportDetails: reportDetails;
  modalClosable: boolean;
  modalScrollable: boolean;
  setGlobalModalVisible(): void;
  setGlobalModalInvisible(): void;
  setGlobalActiveModalContent(newActiveModalContent: activeModalContent): void;
  setReportDetails(newReportDetails: reportDetails): void;
  setModalClosable(): void;
  setModalNotClosable(): void;
  setPageScrollable(): void;
  setPageNotScrollable(): void;
}

export const globalModalStore: globalModalStore = {
  globalModalVisible: false,
  activeModalContent: null,
  reportDetails: null,
  modalClosable: true,
  modalScrollable: true,
  setReportDetails(newReportDetails) {
    this.reportDetails = newReportDetails;
  },
  setGlobalModalVisible() {
    this.globalModalVisible = true;
  },
  setGlobalModalInvisible() {
    this.globalModalVisible = false;
  },
  setGlobalActiveModalContent(newActiveModalContent: activeModalContent) {
    this.activeModalContent = newActiveModalContent;
  },
  setModalClosable() {
    this.modalClosable = true;
  },
  setModalNotClosable() {
    this.modalClosable = false;
  },
  setPageScrollable() {},
  setPageNotScrollable() {},
};
