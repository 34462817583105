import CookieUtils from '../cookie.utils';

const showEditorShortcut = (editorUrl: string) => {
  const div = document.createElement('div');
  div.innerHTML = `<div class="alert alert-info text-center mb-0" role="alert">
      <b>In the mood to change something?</b>
      Edit in
      <a class="underline" href="${editorUrl}" target="_blank">New Editor</a>
      </div>`;
  const header = document.querySelector('.header');
  if (header && div.firstChild) {
    header.appendChild(div.firstChild);
  }
};

export const setEditorShortcuts = () => {
  if (window.analyticsData.b_pageType === 'article' && !window.isPreview) {
    let editorUrl = '';

    if (CookieUtils.get(`editor_login_${process.env.VUE_APP_ENVIROMENT}`) === '1') {
      editorUrl = `${process.env.VUE_APP_EDITOR_URL}/#/articles/${window.analyticsData.uuid}`;
    }
    if (editorUrl) showEditorShortcut(editorUrl);
  }
};
