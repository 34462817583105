import WebAppUtils from './webApp.utils';

/* eslint-disable no-underscore-dangle */
export const Vendors = {
  twitter: '5e71760b69966540e4554f01',
  facebook: '5f1b2fbdb8e05c3057240f56',
  hotjar: '5ee7add94c24944fdb5c5ac6',
  instagram: '5e7e5243b8e05c1c467daa57',
  youtube: '5e7ac3fae30e7d1bc1ebf5e8',
  pinterest: '5e839a38b8e05c4e491e738e',
  ontheio: '5fd9e0fed06a408ae90e7504',
  cleverpush: '5e77928e9cb08971eb078f60',
  emarsys: '5e7794186fbbc471e4b62574',
  recognified: '5ed7abd23e938401fbf5d40b',
  tracdelight: '5e77acddd8d48d795087425b',
  unruly: 162,
  googleAds: '5f1aada6b8e05c306c0597d7',
  googleAnalytics: '5e542b3a4cd8884eb41b5a72',
  podigee: '5e717ad49a0b5040d5750815',
  glomex: '604b382da2286319d61aa96e',
  tiktok: '5e7f6927b8e05c4e491e7380',
  adjust: '5e68dbdd69e7a93e0b259030',
  julep: '61a4af5736795907ea195cfd',
  appIVW: '5efefe25b8e05c065164a2e2',
  optimizely: '5ec515cab8e05c6bd60edc5f',
  zenloop: '62d536352e4e7f05816e388b',
};

/**
 * checks the sourcepoint consent for all configured vendor ids.
 * all consents (accepted | rejected) are written in to window.bunte_consent
 * all accepted vendors are triggered as datalayer event too
 *
 * Reason is that in GTM we need trigger and Blocker.
 * Trigger for e.g. Ads
 * Blocker for things lazy loaded (tigger is intersectObserver)
 */
export const registerConsentCheck = (ivwCallback: (s?: string) => void): void => {
  if (window.__tcfapi) {
    window.__tcfapi('addEventListener', 2, (tcData: any, success: boolean) => {
      if (success) {
        // TODO DMI Add tracking BK-6467
        if (tcData.eventStatus === 'tcloaded') {
          console.log('tcloaded');
          checkAndSetConsent(tcData);
          ivwCallback();
        } else if (tcData.eventStatus === 'cmpuishown') {
          console.log('cmpuishown');
          ivwCallback('consentlayer');
        } else if (tcData.eventStatus === 'useractioncomplete') {
          console.log('useractioncomplete');
          checkAndSetConsent(tcData);
          if (WebAppUtils.isApp()) {
            WebAppUtils.cmpConsentUpdate(tcData);
          }
          ivwCallback();
        }
      }
    });
  } else {
    setTimeout(registerConsentCheck, 20);
  }
};

export const checkAndSetConsent = (tcData: any): void => {
  window.consent = window.consent || {};
  const customVendorIds = [
    Vendors.twitter,
    Vendors.facebook,
    Vendors.hotjar,
    Vendors.instagram,
    Vendors.youtube,
    Vendors.pinterest,
    Vendors.ontheio,
    Vendors.cleverpush,
    Vendors.emarsys,
    Vendors.recognified,
    Vendors.tracdelight,
    Vendors.googleAds,
    Vendors.googleAnalytics,
    Vendors.podigee,
    Vendors.glomex,
    Vendors.tiktok,
    Vendors.adjust,
    Vendors.julep,
    Vendors.appIVW,
    Vendors.optimizely,
    Vendors.zenloop,
  ];
  const globalVendorIds = [Vendors.unruly];

  window.__tcfapi('getCustomVendorConsents', 2, (vendorConsents: any, success: boolean) => {
    if (!success) {
      console.error('getCustomVendorConsents Failed.');
      return;
    }
    const layerEvent: any = {
      event: 'CmpConsentUpdate',
    };
    customVendorIds.forEach((vendorId: string) => {
      layerEvent[`consent_${vendorId}`] = vendorConsents.grants[vendorId] ? vendorConsents.grants[vendorId].vendorGrant : false;
    });
    globalVendorIds.forEach((vendorId: string | number) => {
      layerEvent[`consent_${vendorId}`] = tcData.vendor.consents[vendorId] || false;
    });

    window.dataLayer!.push(layerEvent);
    window.consent = layerEvent;
    const event = new CustomEvent('CmpConsentUpdate', { detail: layerEvent });
    window.dispatchEvent(event);
    WebAppUtils.cmpVendorConsent(layerEvent);
  });
};

export const getConsent = (vendorId: string | number): boolean => {
  if (!window.consent) return false;
  const consentValue = window.consent[`consent_${vendorId}`];
  if (consentValue === undefined) return false;
  return consentValue;
};

/* Display the consent Manager  */
export const showManager = (): void => {
  let consentId;
  if (WebAppUtils.isApp()) {
    // Regular CMP APP
    consentId = process.env.VUE_APP_CONSENT_ID_APP;
  } else {
    // Regular CMP
    consentId = process.env.VUE_APP_CONSENT_ID;
  }
  window._sp_.gdpr.loadPrivacyManagerModal(consentId);
};
window.showCMP = showManager;
/**
 * returns the consent string obtained from sourcepoint
 */
export const getConsentString = (): Promise<void> =>
  new Promise((resolve, reject) => {
    window.__tcfapi('getTCData', 2, (tcData: any, success: boolean) => {
      if (!success) reject();
      resolve(tcData.tcString);
    });
  });

/**
 * returns true if user consent to at least one permission
 */
export const didUserConsent = (detail: any): boolean =>
  Object.keys(detail).reduce((agr: boolean, key: string) => (key.substring(0, 8) === 'consent_' ? agr || detail[key] : agr), false);
